import React, { useEffect, useState } from "react";
// import Web3 from "web3";
import { HOSTING } from "../../../config/config";
import { useWeb3Modal } from "@web3modal/wagmi/react";

// TEST
import ABI_USDT_T from './abi/ABI_USDT_TEST.json'
import ABI_USDC_T from './abi/ABI_USDC_TEST.json'
import ABI_USDT_BSC_T from './abi/ABI_USDT_TEST_BSC.json'
import ABI_USDC_BSC_T from './abi/ABI_USDC_BSC_TEST.json'

// LIVE
import ABI_USDT from './abi/ABI_USDT.json'
import ABI_USDC from './abi/ABI_USDC.json'
import ABI_USDT_BSC from './abi/ABI_USDT_BSC.json'
import ABI_USDC_BSC from './abi/ABI_USDC_BSC.json'
import ABI_AXT from './abi/ABI_AXT.json'


import {
    useAccount,
    useBalance,
    useReadContract,
    useSendTransaction,
    useWaitForTransactionReceipt,
    useWriteContract,
    useChainId,
    useSwitchChain
} from "wagmi";

import { parseEther, parseUnits, formatEther, formatUnits } from 'viem'
import { getBalance } from "viem/actions";

import { useWeb3ModalState } from '@web3modal/wagmi/react'


// <WalletPay
// title="Pay with Web3 Wallet"
// amount={1}
// oninitiate={(initiate) => {
//   console.log({ initiate })
// }}
// onsubmit={(result) => {
//   console.log({ result })
// }}
// toAddress="0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23"
// // disabled={false}
// // loading={false}
// // onClick={() => null}
// token={"USDT"}
// />


const default_tokens = [
    { name: 'USDT', token: 'usdterc20', contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7', abi: ABI_USDT, contract_test: '0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF', abi_test: ABI_USDT_T },
    { name: 'USDC', token: 'usdc', contract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', abi: ABI_USDC, contract_test: '0xDaDE7EAACe436fDfDA26C241628fC21E2240cF9F', abi_test: ABI_USDC_T },
    { name: 'USDT BSC', token: 'usdtbsc', contract: '0x55d398326f99059fF775485246999027B3197955', abi: ABI_USDT_BSC, contract_test: '0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48', abi_test: ABI_USDT_BSC_T },
    { name: 'USDC BSC', token: 'usdcbsc', contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', abi: ABI_USDC_BSC, contract_test: '0x31781Ad0AA9e0A595D18D61365398F310c765B54', abi_test: ABI_USDC_BSC_T },
    // { name: 'AXT', token: 'axt', contract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', abi: ABI_AXT, contract_test: '0xB6CF5b77B92a722bF34f6f5D6B1Fe4700908935E', abi_test: ABI_AXT },
    { name: 'ETH', token: 'eth', contract: null },
    { name: 'BNB Smart Chain', token: 'bnb', contract: null },
    { name: 'Polygon', token: 'matic', contract: null },
]


const USDTTransfer = ({
    onClick = () => null,
    oninitiate,
    onsubmit,
    amount,
    toAddress,
    title,
    style,
    metaMaskLoading = false,
    disabled = false,
    loading,
    tokens = default_tokens,
    token = 'eth',
    supportedChain = 11155111, // sepolia
    className
}) => {

    // amount = 0.001;
    // toAddress = "0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23";

    // const usdt_test_bsc = "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48"; // bsc test 
    // const usdt_test_eth = "0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF"; // Sepolia
    // const usdt_bsc = "0x55d398326f99059fF775485246999027B3197955"; // bsc
    // const usdt_eth = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; 


    const selected_token = tokens.find(_ => _.token == token)

    const contractaddress = HOSTING == "LIVE" ? selected_token?.contract : selected_token?.contract_test ?? '0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF';
    const contractabi = HOSTING == "LIVE" ? selected_token?.abi : selected_token?.abi_test;

    const { open, close } = useWeb3Modal();
    const { address, isConnecting, isDisconnected } = useAccount();


    const { chains, switchChain, error: switchChainError } = useSwitchChain()
    const { selectedNetworkId } = useWeb3ModalState()




    // E T H
    const { data: eth_hash, sendTransaction, error: eth_error, sendTransactionAsync } = useSendTransaction()

    const { data: balance_eth } = useBalance({
        address: address
    })

    const {
        data: successDataEth,
        isLoading: isConfirmingEth,
        isSuccess: isConfirmedEth,
        isError: isErrorEth,
    } = useWaitForTransactionReceipt({
        hash: eth_hash,
    });


    // T O K E N S 

    const {
        data: hash,
        writeContract,
        isPending,
        onSccess,
        error,
    } = useWriteContract();



    const { data: balance } = useReadContract({
        abi: contractabi,
        address: contractaddress,
        functionName: 'balanceOf',
        args: [address]
    })


    const {
        data: successData,
        isLoading: isConfirming,
        isSuccess: isConfirmed,
        isError,
    } = useWaitForTransactionReceipt({
        hash: hash
    });



    useEffect(() => {
        let confirmed = isConfirmed || isConfirmedEth
        let data = successData || successDataEth
        // console.log("---SUCCESS----", { confirmed, data });
        if (confirmed) {
            onsubmit({
                status: true,
                message: "Transaction Success.",
                data: data,
            });
        }
    }, [isConfirmed, successData, isConfirmedEth, successDataEth]);


    useEffect(() => {
        if (error || eth_error || switchChainError) {
            let base_error = eth_error ? JSON.parse(JSON.stringify(eth_error)) : ''
            // console.log("ERROR----", error, base_error, switchChainError.shortMessage);
            onsubmit({
                status: false,
                message: base_error?.shortMessage || error?.shortMessage || error?.message || switchChainError?.shortMessage,
                data: base_error?.shortMessage || error?.shortMessage || error?.message || switchChainError?.shortMessage,
            });
        }
    }, [error, eth_error, switchChainError]);


    const { data: decimals } = useReadContract({
        abi: contractabi,
        address: contractaddress,
        functionName: 'decimals',
    })


    const value = parseUnits(amount.toString(), parseInt(decimals ?? 0))

    // console.log({ decimals, value, address, contractaddress });


    const startTransaction = () => {
        if (parseFloat(balance_eth.formatted) < parseFloat(amount)) {
            onsubmit({
                status: false,
                message: `Not enough balance (${balance_eth.formatted})`,
            });
            return
        }

        // sendTransaction({
        //     to: toAddress,
        //     value: parseEther(amount.toString()),
        // })
        // return

        sendTransactionAsync({
            to: toAddress,
            value: parseEther(amount.toString()),

        }).then(async (hash) => {
            oninitiate({
                status: true,
                message: "Transaction initiated.",
                hash,
            });
        })

    }

    const startTokenTransaction = () => {
        if (balance < value) {
            onsubmit({
                status: false,
                message: `Not enough balance (${formatUnits(balance, Number(decimals))})`,
            });
            return
        }
        writeContract(
            {
                abi: contractabi,
                address: contractaddress,
                functionName: "transfer",
                args: [toAddress, value],
            },
            {
                onSettled: (hash) => {
                    // console.log("SETTLED", hash);
                    if (hash) {
                        oninitiate({
                            status: true,
                            message: "Transaction initiated.",
                            hash,
                        });
                    }
                },
                onError: (error) => {
                    // console.log("ERROR----", error);
                    onsubmit({
                        status: false,
                        message: error.shortMessage || error.message,
                    });
                },
                // onSuccess: (result) => {
                //     // console.log("SUCCESS----", result);
                //     onsubmit({
                //         status: true,
                //         message: "Transaction Success.",
                //         data: result,
                //     });
                // },
            }
        );
    };


    const onclick = () => {
        // check chain and switch 
        // console.log(address , selectedNetworkId , supportedChain);
        // if (address && selectedNetworkId != supportedChain) {
        //     switchChain({
        //         chainId: supportedChain,
        //     })
        //     return
        // }

        // connect or pay
        if (address) {
            if (selected_token?.contract) {
                startTokenTransaction()
            } else {
                startTransaction()
            }
            onClick?.()

        } else {
            open()
        }
    }


    // const switchChainMsg = supportedChain && selectedNetworkId != supportedChain ? `Switch chain to ${chains.find(_ => _.id == supportedChain)?.name}` : null
    const switchChainMsg = null
    return (
        <button
            disabled={disabled || loading}
            className={className}
            onClick={onclick}
            style={style}
        >
            {loading ? "proccessing..." : address ? switchChainMsg ?? title ?? "Send USDT" : "Connect Wallet"}
        </button>
    );
};

export default USDTTransfer;