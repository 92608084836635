import React from "react";
import { formatDateWithoutTime } from "../../utils/formateDate";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MonolineEntry = ({ members }) => {
  const { t } = useTranslation();

  function getLevelSuffix(level) {
    const j = level % 10,
          k = level % 100;
  
    if (j === 1 && k !== 11) {
      return `${level}st`;
    }
    if (j === 2 && k !== 12) {
      return `${level}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${level}rd`;
    }
    return `${level}th`;
  }
  
  return (
    <div className="col-md-6">
      <div className="joinings_viewBox">
        <div className="joinings_viewBox_head">
          <h5>Monoline Entry Position ({members?.length})</h5>
        </div>
        <div className="teammbrs_cnt">
          {!members ? (
            <div className="teammbrs_cnt_row">
              <div className="teammbrs_cnt_img">
                <Skeleton
                  width="45px"
                  height="45px"
                  circle
                  containerClassName="avatar-skeleton"
                  count={3}
                />
              </div>
              <div className="teammbrs_cnt_name_dtl">
                <div className="teammbrs_cnt_name">
                  <Skeleton count={6} />
                </div>
              </div>
            </div>
          ) : members?.length === 0 ? (
            <div className="no-data-div">
              <div className="no-data-div-image">
                <img src="/images/nodata.png" alt="" />
              </div>
              <p className="text-white">{t('noDataFound')}</p>
            </div>
          ) : (
            members.map((member, index) => (
              <div className="teammbrs_cnt_row" key={index}>
                <div className="teammbrs_cnt_img">
                  <img
                    src={
                      member?.image
                        ? member?.image
                        : member?.gender === "F"
                          ? "/images/team1.png"
                          : member?.gender === "M"
                            ? "/images/user-profile.png"
                            : "/images/team3.png"
                    }
                    alt={member?.name}
                  />
                </div>
                <div className="teammbrs_cnt_name_dtl">
                  <div className="teammbrs_cnt_name">
                    {/* {member?.name} {member?.secondName} */}
                    {/* {`${member?.level}`} */}
                  </div>
                  <div className="teammbrs_cnt_date_id">
                  <span>{`Entry on ${getLevelSuffix(member?.level)} level position`}</span>
                    <span>{formatDateWithoutTime(member?.createdAt)}</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

      </div>
    </div>
  );
};

export default MonolineEntry;
