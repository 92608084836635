import React from "react";
import { formatDateWithoutTime } from "../../utils/formateDate";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";

const MonolineBubble = ({ members, monolineData }) => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const handleTileClick = () => {
    nav("/e-wallet/monoline")
  }

  function getLevelSuffix(level) {
    const j = level % 10,
      k = level % 100;

    if (j === 1 && k !== 11) {
      return `${level}st`;
    }
    if (j === 2 && k !== 12) {
      return `${level}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${level}rd`;
    }
    return `${level}th`;
  }

  return (
    <div className="col-md-6">
      <div className="joinings_viewBox">
        <div className="joinings_viewBox_head">
          {/* <h5>Monoline Entry ({members?.length})</h5> */}
          <h5>Monoline Entry: ({monolineData?.monolineTotalCount}/{monolineData?.monolineReferCount})</h5>
          {/* <div className="d-flex justify-content-between text-white" >
          <h5>Rebirth Accounts: ({count})</h5> */}
          <Link to={"/e-wallet/monoline"} className="text-white">View All</Link>
        {/* </div> */}
        </div>
        {/* <div className="rightSide_top_user_dropdown_id my-2 pb-2">
            <div style={{ fontWeight: '900', color: '#f0f0f0', background: 'linear-gradient(124deg, rgba(0, 62, 195, 1) -11%, rgba(2, 27, 80, 1) 39%, rgba(2, 21, 62, 1) 68%, rgba(36, 198, 220, 1) 157%)', display: 'inline-block', padding: '0 20px', borderRadius: '15px' }}>
              MonoLine Entry: 3/5
            </div>
          </div> */}
        <div className="teammbrs_cnt">
          {!members ? (
            <div className="teammbrs_cnt_row">
              <div className="teammbrs_cnt_img">
                <Skeleton
                  width="45px"
                  height="45px"
                  circle
                  containerClassName="avatar-skeleton"
                  count={3}
                />
              </div>
              <div className="teammbrs_cnt_name_dtl">
                <div className="teammbrs_cnt_name">
                  <Skeleton count={6} />
                </div>
              </div>
            </div>
          ) : members?.length === 0 ? (
            <div className="no-data-div">
              <div className="no-data-div-image">
                <img src="/images/nodata.png" alt="" />
              </div>
              <p className="text-white">{t('noDataFound')}</p>
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }} /* className="teammbrs_cnt_row" */ >
              {members.map((member, index) => (
                <div className="monoline-bubble" onClick={handleTileClick}>{member?.level}</div>
              ))}
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default MonolineBubble;
